<template>
  <div class="import-check-sheet-stocks">
    <KTCodePreview v-bind:title="'Danh sách phiếu nhập kho từ phiếu kiểm tồn'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <b-row>
              <b-col>
                <date-picker placeholder="Từ ngày" class="form-control-sm" :config="dpConfigs.date"
                  v-model="dpForm.startDate"></date-picker>
              </b-col>
              <b-col>
                <date-picker placeholder="Đến ngày" class="form-control-sm" :config="dpConfigs.date"
                  v-model="dpForm.endDate"></date-picker>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <MultiselectOption :model.sync="valueStore" :options="filterdOptionsStore" :trackBy="'code'"
              @selectAll="onSelectStore($event)" @removeAll="onRemoveStore($event)" placeholder="cửa hàng" showNoResults
              @searchChange="onInputStoreChange($event)" />
          </b-col>
          <b-col>
            <Autosuggest :model="searchProduct" :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm" :limit="10" @select="onSelectedProduct"
              @change="onInputChangeProduct" suggestionName="productName" />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input v-model="searchId" type="text" placeholder="Mã phiếu" size="sm"
              v-on:keyup.enter="onFilter()"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-model="searchrelateStockCode" type="text" placeholder="Mã phiếu kiểm tồn" size="sm"
              v-on:keyup.enter="onFilter()"></b-form-input>
          </b-col>

          <b-col>
            <b-form-input v-model="searchDesciption" type="text" placeholder="Tìm kiếm theo nội dung" size="sm"
              v-on:keyup.enter="onFilter()"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button style="fontweight: 600; width: 70px" variant="primary" size="sm" @click="onFilter">Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table :items="items" :fields="fields" :busy="onLoading" bordered hover>
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(code)="row">
            <div class="productCode cursor-pointer">
              <a class="font-weight-bold" style="color: black" @click="editItem(row.item)" target="_blank">
                {{ row.item.code }}
              </a>
            </div>
          </template>
          <template v-slot:cell(relateStockCode)="row">
            <div class="productCode cursor-pointer">
              <a class="font-weight-bold text-primary" v-bind:href="getLinkInventoryCheck(row.item.relateStockCode)"
                target="_blank">
                {{ row.item.relateStockCode }}
              </a>
            </div>
          </template>


          <template v-slot:cell(importDate)="row">
            <div style="text-align: left">
              <span v-text="row.item.importDate" class="text-left text-primary mb-0"></span>
              <p class="text-left">{{ row.item.createBy }}</p>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span v-text="row.item.sourceWarehouseId" class="font-weight-bolder"></span>
            <br />
            <span v-if="row.item.companyName">( {{ row.item.companyName }} )</span>
          </template>
          <template v-slot:cell(description)="row">
            <div @mouseover="hoverEditNote = true" @mouseleave="hoverEditNote = false">
              <span v-text="row.item.description" class="mr-2"></span>
              <span @click="handleShowNoteModel(row.item)">
                <i v-if="hoverEditNote" class="fas fa-edit"></i>
              </span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i style="font-size: 1rem" class="flaticon2-settings pr-0"></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span class="action-item">
                    <i class="flaticon2-pen"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav v-model="page" class="custom-pagination" v-show="totalPages >= 2" :link-gen="linkGen"
              :number-of-pages="totalPages" use-router @change="fetchData" align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1" prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 " last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1">
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal v-model="showEditNoteModal" hide-footer :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode">
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice">Ghi chú</label>
                <b-form-textarea v-model="description" placeholder="Nhập ghi chú..." rows="5"
                  max-rows="6"></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button style="fontweight: 600; width: 70px" variant="primary" size="sm"
                  @click="updateNote">Lưu</b-button>
                <b-button style="margin-left: 10px; font-weight: 600; width: 70px" variant="secondary" size="sm"
                  @click="handleShowNoteModel">Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { TIME_TRIGGER } from '@/utils/constants';
import { VclTable } from 'vue-content-loading';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import { removeAccents, convertPrice } from '@/utils/common';
import { cloneDeep, map, find, assign } from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import MultiselectOption from '@/view/base/multiselect/MultiselectOption.vue';
import { STOCK_SLIP_TYPE } from '@/utils/enum';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      items: [],
      searchDesciption: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thClass: 'text-center align-middle',
          tdClass: 'text-left align-middle',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'relateStockCode',
          label: 'Từ phiếu kiểm tồn',
          tdClass: 'text-left align-middle',
          sortable: false,
          thClass: 'text-center align-middle',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          tdClass: 'text-left align-middle',
          sortable: false,
          thClass: 'text-center align-middle',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          thClass: 'text-center align-middle',
          tdClass: 'text-left align-middle',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'importDate',
          label: 'Ngày tạo',
          sortable: false,
          thClass: 'text-center align-middle',
          tdClass: 'text-left align-middle',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'actions',
          tdClass: 'text-center align-middle', 
          label: '', thStyle: { width: '5%' }
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [],
      optionsStore: [],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      hoverEditNote: false,
      stockSlipCode: '',
      showEditNoteModal: false,
      description: '',
      searchrelateStockCode: '',
      selectedId: [],
      checkAllItem: false,
      filterdOptionsStore: []
    };
  },
  methods: {
    convertPrice,
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchData();
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const listStoreId = this.optionsStore.filter((element) => element.checked).map((element) => element.code);

      const params = {
        page: this.page,
        pageSize: 10,
        searchDesciption: this.searchDesciption.trim(),
        searchCode: this.searchId.trim(),
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        type: STOCK_SLIP_TYPE.IMPORT_STOCK_BY_INVENTORY_CHECK,
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        relateStockCode: this.searchrelateStockCode.trim(),
      };

      ApiService.query('check-stock', { params })
        .then((response) => {
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.items = stockResponse.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              providerId: item.providerId,
              description: item.description,
              totalAmount: item.totalAmount,
              type: item.type,
              importDate: moment(String(item.importDate)).format('DD/MM/YYYY'),
              createBy: item.createBy,
              updateBy: item.updateBy,
              relateStockCode: item.relateStockCode,
              selected: false,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-export-check-sheet-stock',
        query: { id: item.id },
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-import-check-sheet-stock',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      const index = this.optionsStore.findIndex(
        (item) => item.name === option.name
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      const index = this.optionsStore.findIndex(
        (item) => item.name === option.name
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, obj => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter(item => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/import-check-sheet-stocks/update?id=${item.id}`;
    },
    handleShowNoteModel(item) {
      this.showEditNoteModal = !this.showEditNoteModal;
      this.stockSlipCode = item.code;
      this.description = item.description;
    },
    updateNote() {
      const data = {
        stockSlipCode: this.stockSlipCode,
        description: this.description,
      };
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditNoteModal = !this.showEditNoteModal;
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getLinkInventoryCheck(code) {
      return `#/inventory-check/upsert?code=${code}`;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhập kho từ phiếu kiểm tồm', route: 'import-check-sheet-stocks' },
      { title: 'Danh sách phiếu nhập kho từ phiếu kiểm tồn' },
    ]);
  },
  created() {
    let code = this.$route.query.code;
    if (code !== undefined) {
      this.searchId = code;
    }
    this.fetchStore();
    this.fetchCompany();
    this.fetchStoreByUser();
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    MultiselectOption
  },
};
</script>

<style lang="scss">
.import-check-sheet-stocks {

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm>.btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }

  .productCode:hover {
    text-decoration: underline;
  }

  .checkbox-label {
    display: block;
  }

  .page-item.disabled {
    cursor: not-allowed;

    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }

  .action-item {
    i {
      font-size: 1rem;
    }

    color: #3f4254;
    font-size: 12px;
  }
}
</style>
